import { useConfigProperty } from '@leagueplatform/core';
import { useAnalyticsIdentify } from '@leagueplatform/web-common';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { BasePage } from 'components/BasePage';
import { OnboardingFeatureHighlights } from 'components/onboarding-feature-highlights/onboarding-feature-highlights.component';
import { useOnboarding } from 'hooks/use-onboarding.hook';
import { useMemo } from 'react';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { onOnboardingComplete, shouldShowFeatureHighlights, isLoading } =
    useOnboarding();

  // Send user data to analytics SDK
  const tenantId = useConfigProperty('core.tenantId');
  const identifyOptions = useMemo(
    () => ({
      employer_group_id: '',
      tenant_id: tenantId!,
    }),
    [tenantId],
  );
  useAnalyticsIdentify(identifyOptions);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (shouldShowFeatureHighlights) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={onOnboardingComplete}
      />
    );
  }
  return <BasePage>{children}</BasePage>;
};
